import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Upload, Button, Select, message } from 'antd';
import axiosConfig from '../../utils/axios-config';
import { UploadOutlined } from '@ant-design/icons';
import { GetFileExtension } from '../../utils/file-utils';
import { AllowedFileTypes } from '../../constants/document';
import { useSelector } from 'react-redux';
import cookies from 'js-cookie';
import './upload-file.scss';

const OcrUploadFile = () => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState(
    <Select.Option value='none'>None</Select.Option>
  );
  const [modelId, setModelId] = useState('');
  const mountedRef = useRef(true);

  useEffect(() => {
    fetchModel();
  }, [mountedRef]);

  const fetchModel = useCallback(() => {
    if (currentUser) {
      axiosConfig({
        method: 'get',
        url: `/api/nanonets/models`,
        headers: {
          Authorization: `Bearer ${cookies.get('access_token')}`,
        },
      })
        .then((response) => {
          const options = response.data.data.map((res) => {
            return (
              <Select.Option key={res.id} value={res.modelId}>
                {res.modelName}
              </Select.Option>
            );
          });
          setOptions(options);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            message.error(error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          setLoading(false);
          console.log(error.config);
        });
    }
  }, [mountedRef]);

  const currentUser = useSelector((state) => state.auth.currentUser);


  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
    setModelId(value);
  };

  const handleUpload = async () => {
    if (!modelId)
    {
      message.error("You need to select Model before uploading!");
    }

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file);
    });

    try {
      setUploading(true);
      let response = await axiosConfig({
        method: 'post',
        url: `/api/blob/${modelId}/uploaddocument`,
        data: formData,
        headers: {
          Authorization: `Bearer ${cookies.get('access_token')}`,
        },
      });
      console.log(response);
      message.success(response.data.message);
    } catch (error) {
      console.log(error);
      message.error(error.response.data);
    } finally {
      setUploading(false);
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      let fileExtension = GetFileExtension(file.name);
      if (!AllowedFileTypes.includes(fileExtension.toLowerCase())) {
        message.error('File Upload must be .pdf, .png, .jpg or .jpeg', 3);
      } else {
        setFileList([file]);
      }
      return false;
    },
    fileList,
  };

  return (
    <div className='ocr-upload-page'>
      <div className='ocr-title'>
        <span className='text'>UPLOAD FILE</span>
      </div>
      <div>
        <Select
          style={{ width: 200 }}
          placeholder='Select Models'
          className='select-model'
          onChange={handleChange}
        >
          {options}
        </Select>
      </div>
      <div className='upload-container'>
        <Upload {...props} maxCount={1}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </div>
      <div>
        <Button
          type='primary'
          onClick={handleUpload}
          disabled={fileList.length === 0 || uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? 'Uploading' : 'Start Upload'}
        </Button>
      </div>
    </div>
  );
};

export default OcrUploadFile;
