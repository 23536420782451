import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import './edit-setting.scss';

const UpdateSetting = () => {
	const params = useParams();
	let history = useHistory();
	const [currentConfigs, setCurrentConfigs] = useState({});
	const tailLayout = {
		wrapperCol: {
			offset: 4,
			span: 12,
		},
	};

	const [form] = Form.useForm();

	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	const fetchSetting = async () => {
		console.log(params);
		return await axiosConfig({
			method: 'get',
			url: `/api/roxuslogging/clients/setting/${params.settingId}`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				let setting = response.data;
				console.log('Setting', setting);
				let customSettingsStr = setting.customSettings;
				let currentConfigs = {};
				currentConfigs.ClientName = setting.clientName;
				currentConfigs.ProcessName = setting.processName;
				currentConfigs.TriggerName = setting.triggerName;

				if (isJsonString(customSettingsStr)) {
					let configs = [];
					let customSettings = JSON.parse(customSettingsStr);
					for (const [key, value] of Object.entries(customSettings)) {
						console.log(`${key}: ${value}`);
						let config = {};
						config.Name = key;
						config.Value = value;
						configs.push(config);
					}

					currentConfigs.Configs = configs;
					setCurrentConfigs(currentConfigs);
					console.log('Current Configs', currentConfigs);
					form.setFieldsValue(currentConfigs);
				}
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	};

	const onFinish = async (values) => {
		console.log(JSON.stringify(values));

		await axiosConfig({
			method: 'put',
			data: values,
			url: `/api/roxuslogging/clients/setting-new/${params.settingId}`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				history.push('/rpa/process-config');
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	};

	const onReset = () => {
		form.resetFields();
	};

	const changePage = (page) => {
		switch (page) {
			case 'process-config':
				history.push('/rpa/process-config');
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		document.title = 'Roxus | Edit Setting';
		fetchSetting();
	}, []);

	return (
		<>
			<div className='ocr-list-page'>
				<div className='ocr-title'>
					<span className='text'>EDIT SETTING</span>
				</div>
			</div>
			<Form
				labelCol={{
					span: 4,
				}}
				wrapperCol={{
					span: 16,
				}}
				form={form}
				name='dynamic_form_complex'
				onFinish={onFinish}
				autoComplete='off'
				key={currentConfigs}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<>
						<Form.Item
							label='Client Name'
							name='ClientName'
							rules={[
								{
									required: true,
									message: 'Client Name is required',
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label='Process Name'
							name='ProcessName'
							rules={[
								{
									required: true,
									message: 'Process Name is required',
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item label='Trigger Name' name='TriggerName'>
							<Input />
						</Form.Item>
						<Form.Item label='Config'>
							<Form.List name={'Configs'}>
								{(subFields, subOpt) => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										{subFields.map((subField, index) => (
											<Space
												key={subField.key}
												style={{
													alignItems: 'unset',
												}}
											>
												<Form.Item
													name={[
														subField.name,
														'Name',
													]}
												>
													<Input
														placeholder={`Config ${
															index + 1
														}`}
													/>
												</Form.Item>
												<Form.Item
													name={[
														subField.name,
														'Value',
													]}
													style={{
														width: '600px',
													}}
												>
													<Input
														placeholder={`Value ${
															index + 1
														}`}
													/>
												</Form.Item>
												<CloseOutlined
													onClick={() => {
														subOpt.remove(
															subField.name
														);
													}}
												/>
											</Space>
										))}
										<Button
											type='dashed'
											onClick={() => subOpt.add()}
											block
										>
											+ Add New Config
										</Button>
									</div>
								)}
							</Form.List>
						</Form.Item>
					</>
				</div>
				<Form.Item {...tailLayout}>
					<Button type='primary' htmlType='submit'>
						Save
					</Button>
					<Button htmlType='button' onClick={onReset}>
						Clear
					</Button>
					<Button
						htmlType='button'
						onClick={() => changePage('process-config')}
					>
						Don't Save
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default UpdateSetting;
