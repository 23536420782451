import React, { useState } from "react";
import { Row, Col, Card, Form, Input, Divider, Spin, message } from "antd";
import GraButton from "../button/gra-button";
import axiosConfig from "../../utils/axios-config";
import cookies from "js-cookie";
import PasswordChecklist from "react-password-checklist"
import { withRouter } from "react-router";
import { UserMessage } from "../../constants/user";
import "./security-tab.scss";

const SecurityTab = (props) => {
    const { history } = props;
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validPassword, setValidPassword] = useState(false);

    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
    };

    const onFinish = (values) => {

        if (!validPassword) {
            message.error("New Password doesn't satisfy Password Policy");
            return;
        }

        setLoading(true);

        console.log("Received values of form: ", values);
        axiosConfig({
            method: "post",
            url: `/api/nanonets/users/change-password`,
            data: values,
            headers: { Authorization: `Bearer ${cookies.get("access_token")}` },
        })
            .then((response) => {
                message.success(UserMessage.ChangePassword_200);
                cookies.remove("access_token");
                cookies.remove("refresh_token");
                setTimeout(function () {
                    history.push("/login");
                }, 2000);
                setLoading(false);
            })
            .catch((error) => {
                message.error(error.response.data);
                setLoading(false);
            });
    };

    return (
        <div className='security-tab'>
            <Card title='Change Password'>
                <Spin spinning={loading}>
                    <Form
                        {...formItemLayout}
                        form={form}
                        name='change-password'
                        onFinish={onFinish}
                        initialValues={{
                            prefix: "44",
                        }}
                        scrollToFirstError
                    >
                        <Row>
                            <Col span={10}>
                                <Form.Item
                                    name='currentPassword'
                                    label='Current Password'
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please input your current password!",
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={10}>
                                <Form.Item
                                    name='newPassword'
                                    label='New Password'
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please input your new password!",
                                        },
                                    ]}
                                    onChange={e => setNewPassword(e.target.value)}
                                    hasFeedback
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item
                                    name='confirmPassword'
                                    label='Confirm Password'
                                    dependencies={["newPassword"]}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please confirm your new password!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue(
                                                        "newPassword"
                                                    ) === value
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    new Error(
                                                        "The two passwords that you entered do not match!"
                                                    )
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Col offset={8}>
                                    <Form.Item>
                                        <PasswordChecklist
                                            rules={["minLength", "specialChar", "number", "capital"]}
                                            minLength={10}
                                            value={newPassword}
                                            valueAgain={confirmPassword}
                                            onChange={(isValid) => { setValidPassword(isValid) }}
                                        />
                                    </Form.Item>
                                </Col>

                            </Col>
                            <Divider />
                            <Col span={24} className='submit-button'>
                                <Form.Item>
                                    <GraButton
                                        className='button-gra-inv'
                                        type='submit'
                                    >
                                        Change Password
                                    </GraButton>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Card>
        </div>
    );
};

export default withRouter(SecurityTab);
