import React, { useState, useEffect } from 'react';
import { Spin, Table, Tag, Space, Tabs  } from 'antd';
import cookies from 'js-cookie';
import { Button, message } from 'antd';
import axiosConfig from '../../utils/axios-config';
import parse from 'html-react-parser';
import { capitalize } from '../../utils/string-util';
import { useSelector } from 'react-redux';
import './ocr-table.scss';

const OcrTable = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    let isMounted = true;
    if (currentUser && currentUser.id) {
      fetchTable().then((response) => {
        const data = response.data.data.map((res, index) => {
          index++;
          return {
            key: res.id,
            no: index,
            fileName: res.fileName,
            fileUrl: res.blobUrl,
            status: res.status,
            model: res.modelName,
            pages: res.ocrPages,
            createdTime: res.createdTime,
            predictionTime: res.predictionTime ? res.predictionTime : '',
            moderatedUrl: res.moderatedUrl,
          };
        });
        if (isMounted) {
          setTableData(data);
          setLoading(false);
        }
      })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          if (isMounted) {
            setLoading(false);
          }
          console.log(error.config);
        });
    }
    return () => { isMounted = false };
  }, []);

  const fetchTable = async () => {
    return await axiosConfig({
      method: 'get',
      url: `/api/nanonets/users/${currentUser.id}/ocr-documents`,
      headers: {
        Authorization: `Bearer ${cookies.get('access_token')}`,
      },
    })
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      render: (model) => {
        let color = '';
        let text = '';
        switch (model) {
          case 'Invoice':
            color = 'cyan';
            text = 'Invoice';
            break;
          case 'Number Plate':
            color = 'geekblue';
            text = 'Number Plate';
            break;
          case 'Traffic Enforcement':
            color = 'purple';
            text = 'Traffic Enforcement';
            break;
          default:
            break;
        }
        return (
          <Tag color={color} key={model}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => {
        let color = '';
        let text = '';
        switch (status) {
          case 1:
            color = 'red';
            text = 'Uploaded';
            break;
          case 2:
            color = 'blue';
            text = 'Predicted';
            break;
          case 3:
            color = 'green';
            text = 'Validated';
            break;
          default:
            break;
        }
        return (
          <Tag color={color} key={status}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Space size='middle'>
            <a target='_blank' rel='noreferrer' href={record.fileUrl}>
              View File
            </a>
            {record.status === 1 && (
              <Button
                className='predict-button'
                type='link'
                onClick={() => predictFile(record.key)}
              >
                Predict File
              </Button>
            )}
            {record.moderatedUrl && (
              <a target='_blank' rel='noreferrer' href={record.moderatedUrl}>
                Verify File
              </a>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Created Time',
      key: 'createdTime',
      dataIndex: 'createdTime',
      render: (text) => {
        return new Date(text).toISOString().substr(0, 19).replace('T', ' ');
      },
    },
    {
      title: 'Prediction Time',
      key: 'predictionTime',
      dataIndex: 'predictionTime',
      render: (text) => {
        return (
          text && new Date(text).toISOString().substr(0, 19).replace('T', ' ')
        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    console.log('Expand Record', record);
    let recordNo = record.no;
    const predictColumns = [
      {
        title: 'Page',
        dataIndex: 'page',
        key: recordNo,
        width: 100,
        render: (text, record, index) => {
          let pageNumber = parseInt(text);
          let rowSpan = record.pageStart[pageNumber - 1];
          if (pageNumber > 1)
          {
            rowSpan = rowSpan - record.pageStart[pageNumber - 2];
          }
          if (index == 0 || record.pageStart.includes(index))
          {
            return {
              children: pageNumber,
              props: {
                rowSpan: rowSpan,
              },
            };
          }
          else
          {
            return {
              props: {
                rowSpan: 0,
              },
            };
          }
        },
      },
      {
        title: 'Prediction Data',
        dataIndex: 'predictLabel',
        key: 'predictLabel',
        colSpan: 3,
        render: (text) => {
          return {
            children: text && (
              <span className='ocr-label'>{capitalize(text)}</span>
            ),
          };
        },
      },
      {
        title: 'Prediction Text',
        dataIndex: 'predictOcrText',
        key: 'predictOcrText',
        colSpan: 0,
        render: (text, record) => {
          if (text === "table" || record.predictLabel === "table") {
            let tableSource = [];
            let tableColumns = [];
            let ocrTable = record.predictOcrTable;
            let startRow = 1;
            let rowData = {};
            rowData.key = startRow;
            if (ocrTable)
            {
              for (let index = 0; index < ocrTable.length; index++) {
                const cell = ocrTable[index];
                let row = cell.row;
                let col = cell.col;
                let label = cell.label;
                let text = cell.text;
                if (row == 1)
                {
                  let column = {};
                  if (label)
                  {
                    column.title = capitalize(label);
                    column.dataIndex = "col" + col;
                    column.key = "col" + col;
                  }
                  else
                  {
                    column.title = col;
                    column.dataIndex = "col" + col;
                    column.key = "col" + col;
                  }
                  tableColumns.push(column);
                }
                if (row == startRow)
                {
                  rowData["col" + col] = text;
                }
                else
                {
                  tableSource.push(rowData);
                  startRow = row;
                  rowData = {};
                  rowData.key = startRow;
                  rowData["col" + col] = text;
                }
              }
            }
            tableSource.push(rowData);

            return {
              children: <Table key={`table-${record.pageId}-${record.pageNumber}`} className='child-table' dataSource={tableSource} columns={tableColumns} />
            };
          }
          else {
            return {
              children:
                text && parse(text.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')),
            };
          }
        },
      },
      {
        title: 'Prediction Score',
        dataIndex: 'score',
        key: 'score',
        colSpan: 0,
        render: (text) => {
          if (!text)
          {
            return "";
          }
          let score = parseFloat(text) * 100;
          if (score >= 90) {
            return {
              children: <Tag color='green'>{score.toFixed(2)}</Tag>,
            };
          } else if (score >= 80 && score < 90) {
            return {
              children: <Tag color='gold'>{score.toFixed(2)}</Tag>,
            };
          } else {
            return {
              children: <Tag color='red'>{score.toFixed(2)}</Tag>,
            };
          }
        },
      },
    ];
    const moderatedColumns = [
      {
        title: 'Page',
        dataIndex: 'page',
        key: recordNo,
        width: 100,
        render: (text, record, index) => {
          let pageNumber = parseInt(text);
          let rowSpan = record.pageStart[pageNumber - 1];
          if (pageNumber > 1)
          {
            rowSpan = rowSpan - record.pageStart[pageNumber - 2];
          }
          if (index == 0 || record.pageStart.includes(index))
          {
            return {
              children: pageNumber,
              props: {
                rowSpan: rowSpan,
              },
            };
          }
          else
          {
            return {
              props: {
                rowSpan: 0,
              },
            };
          }
          
        },
      },
      {
        title: 'Moderated Data',
        dataIndex: 'moderatedLabel',
        key: 'moderatedLabel',
        colSpan: 3,
        render: (text) => {
          return {
            children: text && (
              <span className='ocr-label'>{capitalize(text)}</span>
            ),
          };
        },
      },
      {
        title: 'Moderated Text',
        dataIndex: 'moderatedOcrText',
        key: 'moderatedOcrText',
        colSpan: 0,
        render: (text, record) => {
          if (text == "table" || record.moderatedLabel === "table") {
            let tableSource = [];
            let tableColumns = [];
            let ocrTable = record.moderatedOcrTable;
            let startRow = 1;
            let rowData = {};
            rowData.key = startRow;
            for (let index = 0; index < ocrTable.length; index++) {
              const cell = ocrTable[index];
              console.log("Cell", cell);
              let row = cell.row;
              let col = cell.col;
              let label = cell.label;
              let text = cell.text;
              if (row == 1)
              {
                let column = {};
                if (label)
                {
                  column.title = capitalize(label);
                  column.dataIndex = "col" + col;
                  column.key = "col" + col;
                }
                else
                {
                  column.title = col;
                  column.dataIndex = "col" + col;
                  column.key = "col" + col;
                }
                tableColumns.push(column);
              }
              if (row == startRow)
              {
                rowData["col" + col] = text;
              }
              else
              {
                tableSource.push(rowData);
                startRow = row;
                rowData = {};
                rowData.key = startRow;
                rowData["col" + col] = text;
              }
            }
            tableSource.push(rowData);

            return {
              children: <Table className='child-table' dataSource={tableSource} columns={tableColumns} />
            };
          }
          else {
            return {
              children:
                text && parse(text.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')),
            };
          }
        },
      },
      {
        title: 'Moderated Score',
        dataIndex: 'moderatedScore',
        key: 'moderatedScore',
        colSpan: 0,
        render: (text) => {
          if (!text)
          {
            return "";
          }
          let score = parseFloat(text) * 100;
          if (score >= 90) {
            return {
              children: <Tag color='green'>{score.toFixed(2)}</Tag>,
            };
          } else if (score >= 80 && score < 90) {
            return {
              children: <Tag color='gold'>{score.toFixed(2)}</Tag>,
            };
          } else {
            return {
              children: <Tag color='red'>{score.toFixed(2)}</Tag>,
            };
          }
        },
      },
    ];
    let pageRecords = record.pages;
    let predictData = [];
    let moderatedData = [];
    let predictedArr = [];
    let moderatedArr = [];
    let predictRowCount = 0;
    let moderateRowCount = 0;
    
    pageRecords.forEach((page) => {
      let pageId = page.id;
      let predictionRecords = JSON.parse(page.predictionData);
      let predictionLength = 0;
      let sortedPredictionRecords = [];
      if (predictionRecords)
      {
        predictionLength = predictionRecords.length;
        sortedPredictionRecords = predictionRecords.sort((a, b) => a.label?.localeCompare(b.label));
        predictedArr.push(predictRowCount + predictionLength);
        // console.log("Predicted Array", predictedArr);
        for (let index = 0; index < predictionLength; index++) {
          let predict = sortedPredictionRecords[index];
          let dataObj = {
            key: "predict-" + pageId + "-" + predictRowCount,
            page: page.page + 1,
            pageStart: predictedArr,
            length: predictionLength,
            predictLabel: predict.label,
            predictOcrText: predict.ocr_text,
            predictOcrTable: predict.cells,
            score: predict.score
          };
          predictData.push(dataObj);
          predictRowCount++;
        }
      }
      var moderatedRecords = JSON.parse(page.moderatedData);
      let moderatedLength = 0;
      let sortedModeratedRecords = [];
      if (moderatedRecords)
      {
        moderatedLength = moderatedRecords.length;
        sortedModeratedRecords = moderatedRecords.sort((a, b) => a.label?.localeCompare(b.label));
        moderatedArr.push(moderateRowCount + moderatedLength);
        for (let index = 0; index < moderatedLength; index++) {
          let moderated = sortedModeratedRecords[index];
          let dataObj = {
            key: "moderate-" + pageId + "-" + moderateRowCount,
            page: page.page + 1,
            pageStart: moderatedArr,
            length: moderatedLength,
            moderatedLabel: moderated.label,
            moderatedOcrText: moderated.ocr_text,
            moderatedOcrTable: moderated.cells,
            moderatedScore: moderated.score
          }
          moderatedData.push(dataObj);
          moderateRowCount++;
        }
      }
    });

    // console.log("Final Predict Data", predictData);
    console.log("Final Moderated Data", moderatedData);
    return (
      <>
        <Tabs>
          <Tabs.TabPane tab="Predicted Data" key="predicted-data">
            <Table className='predicted-table' columns={predictColumns} dataSource={predictData} pagination={false} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Moderated Data" key="moderated-data">
            <Table className='moderated-table' columns={moderatedColumns} dataSource={moderatedData} pagination={false} />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  };

  const predictFile = (documentId) => {
    setLoading(true);
    axiosConfig({
      method: 'post',
      url: `/api/nanonets/ocr-documents/${documentId}/predict`,
      headers: { Authorization: `Bearer ${cookies.get('access_token')}` },
    })
      .then((response) => {
        console.log(response);
        message.success(response.data.message);
        fetchTable();
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          message.error(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        message.error(error.response.data);
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={tableData}
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record),
          rowExpandable: (record) => record.status !== 1,
        }}
      />
    </Spin>
  );
};

export default OcrTable;
